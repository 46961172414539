<template>
  <div v-click-outside="onClickOutside">
    <v-card class="sc-chat-window" border>
      <v-toolbar color="primary" light dark dense>
        <v-avatar size="36">
          <img :src="`/avatar-whire.png`" />
        </v-avatar>
        <v-toolbar-title class="mx-2">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{ $t("HelpCenter") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <small
            v-if="typing"
            class="text-white"
          >{{ typing }} is typing</small> -->
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row class="d-flex flex-row justify-end mt-2 px-3">
        <v-btn text @click="back" class="btn">
          <v-icon color="black"> mdi-chevron-left</v-icon> {{ $t("Back") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="back" color="primary" class="btn">
          {{ $t("Switchtointernal") }}
        </v-btn>
      </v-row>

      <v-row class="d-flex flex-row justify-end mt-2 px-3">
        <v-col cols="12"
          ><br />
          <p class="text-center">
            {{ $t("suportExplainHi") }} {{ getUserName(currentUser.name) }}!
            {{ $t("suportExplain") }}
          </p>
        </v-col>
      </v-row>
      <div ref="scrollList" class="sc-message-list">
        <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
          <v-container>
            <v-col cols="12">
              <v-col cols="12">
                <v-textarea
                  auto-grow
                  solo
                  name="input-7-4"
                  :label="$t('GeneralAssistance')"
                  color="primary"
                  rows="6"
                  v-model="message"
                ></v-textarea>
              </v-col>
              <v-row
                class="d-flex flex-row justify-end align-center mt-5 px-3 "
              >
                <v-btn color="primary" class="mr-4" @click="sendMessage">
                  {{ $t("send") }}
                </v-btn>
              </v-row>
            </v-col>
          </v-container>
        </v-row>
      </div>
    </v-card>
    <chat-message
      v-if="showChatSuporte"
      @initChatSuport="$emit('initChatSuport')"
      @close="$emit('close')"
      @initSuport="$emit('initSuport')"
    />
  </div>
</template>

<script>
// import { CHATS_QUERY } from "./../../graphql/Query";
import chatMessage from "./chatMessage.vue";
import { SEND_MESSAGE_OUT } from "./../../graphql/Mutation";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data: () => ({
    selected: null,
    message: "",
    sendFlag: true,
    showChatSuporte:false,
  }),
  components: { chatMessage },
  computed: {
    icon() {
      return this.icons[this.iconIndex];
    },
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },

  methods: {
    back() {
      this.$emit("init");
    },
    close() {
      this.$emit("close");
    },
    onClickOutside(event, el) {
      console.log(event, el);
      this.close();
    },
    async initChat() {
     
    },
    sendMessage() {
      if (this.message != "") {
        this.saveMessage();
        this.clearMessage();
        this.initChat();
      }
    },
    clearMessage() {
      this.message = "";
    },
    getUserName(name) {
      name = name.split(" ");
      return name[0];
    },
    async saveMessage() {
      this.sendFlag = false;
      await this.$apollo
        .mutate({
          mutation: SEND_MESSAGE_OUT,
          client:'apolloClientWhirelab',
          variables: {
            nameRoom: this.currentUser.id,
            body: this.message,
            time: moment().format("LT"),
            date: moment().format("LL"),
            testID: null,
            to: {id:this.currentUser.id,name:this.currentUser.name,photo:this.currentUser.photo,type:this.currentUser.type},
            flag: "Suport",
            author: {id:this.currentUser.id,name:this.currentUser.name,photo:this.currentUser.photo,type:this.currentUser.type},
            from:"Amer"

          },
        })
        .then((res) => {
          if (res.data.sendMessageOutSide.name) {
            this.sendFlag = true;
             this.showChatSuporte = !this.showChatSuporte
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped src="@/assets/css/chat_css/chatList.css"></style>
